import 'core-js/stable'
import 'stylesheets/button-locale.scss'

import * as RailsUjs from '@rails/ujs'
import * as Turbolinks from 'turbolinks'
import { registerComponent } from 'shared/helper/setup'

Turbolinks.start()
// Built vite bundles invoke this implicitly
if (import.meta.env.DEV) {
  RailsUjs.start()
}

registerComponent('misc/TrackingVisualizer', () => import('devise/misc/TrackingVisualizer'))
registerComponent('SetPassword', () => import('devise/SetPassword'))
registerComponent('molecule/SetPasswordButton', () => import('devise/molecule/SetPasswordButton'))
